import React from 'react';
import { Carousel, Image } from 'react-bootstrap';
import banner1 from '../../assets/images/banner1.png';
import banner2 from '../../assets/images/banner2.png';
import banner3 from '../../assets/images/banner3.png';
import banner1Mobile from '../../assets/images/banner1-mobile.png';
import banner2Mobile from '../../assets/images/banner2-mobile.png';
import banner3Mobile from '../../assets/images/banner3-mobile.png';
import './style.css';

export default () => {
  const width = document.documentElement.clientWidth;
  const THRESHOLD = 768;

  return (
    <Carousel id="banners-container">
      <Carousel.Item>
        <Image className="banner" src={width > THRESHOLD ? banner1 : banner1Mobile} />
      </Carousel.Item>
      <Carousel.Item>
        <Image className="banner" src={width > THRESHOLD ? banner2 : banner2Mobile} />
      </Carousel.Item>
      <Carousel.Item>
        <Image className="banner" src={width > THRESHOLD ? banner3 : banner3Mobile} />
      </Carousel.Item>
    </Carousel>
  );
};
