import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './pages/Home';
import Instruction from './pages/Instruction';

function App() {
  return (
    <div className="jn-container">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/sizing">
            <Instruction />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
