/* eslint-disable no-unused-vars */
import React from 'react';
import { Image } from 'react-bootstrap';
import logoFb from '../../assets/images/social-fb.png';
import logoIg from '../../assets/images/social-ig.png';
import logoTiktok from '../../assets/images/social-tiktok.png';

import './style.css';

export default () => (
  <div className="footer-container">
    <div className="footer-sections">
      <div className="footer-section" style={{ width: '40%' }}>
        <h1 className="font-semibold">LIÊN HỆ</h1>
        <p className="font-extra-light">
          Điện thoại: 036 3780008
          <br />
          Email: alo@justnails.vn
        </p>
      </div>
      <div className="footer-section" style={{ width: '45%' }}>
        <h1 className="font-semibold">ĐỊA CHỈ</h1>
        <p className="font-extra-light">
          2 Lê Văn Miến, Phường Thảo Điền,
          <br />
          Quận 2, Thành phố Thủ Đức
        </p>
      </div>
      <div className="footer-section" style={{ width: '15%' }}>
        <h1 className="font-semibold">GIỜ MỞ CỬA</h1>
        <p className="font-extra-light">
          Thứ Hai - Chủ Nhật
          <br />
          9:00 - 21:00
        </p>
      </div>
    </div>
    <div className="footer-sections">
      <div className="footer-section" style={{ width: '40%' }}>
        <div className="font-bold">
          <span>&copy; 2021 by The Just Group Limited.</span>
        </div>
      </div>
      <div className="footer-section" style={{ width: '45%' }} />
      <div className="footer-section" style={{ width: '15%' }}>
        <div className="logos">
          <Image src={logoFb} />
          <Image src={logoIg} />
          <Image src={logoTiktok} />
        </div>
        <small className="font-bold">/justnailsvn</small>
      </div>
      <div className="footer-section" style={{ display: 'none' }}>
        <small className="font-bold">
          &copy; 2021 by The Just Group Limited.
        </small>
      </div>
    </div>
  </div>
);
