import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.css';

const JnButton = ({
  children, className, href, target,
}) => <Button href={href} target={target} className={`${className} jn-button`}>{children}</Button>;
JnButton.propTypes = {
  children: PropTypes.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
};

JnButton.defaultProps = {
  className: null,
  href: undefined,
  target: undefined,
};

export default JnButton;
