import React from 'react';
import { IG_POSTS } from '../../constants/links';
import InstagramPost from './InstagramPost/InstagramPost';
import './style.css';

const post = (i) => (
  <InstagramPost
    src={IG_POSTS[i].src}
    caption={IG_POSTS[i].caption}
    date={IG_POSTS[i].date}
    href={IG_POSTS[i].url}
  />
);

export default () => (
  <div>
    <div className="instagram-header font-bold">#JustNailsVN</div>
    <div id="instagram-sub-header" style={{ fontFamily: '@font-regular', textAlign: 'center', marginBottom: '2.5%' }}>
      Theo dõi
      {' '}
      <strong>@justnailsvn</strong>
      {' '}
      để cập nhật những mẫu nails mới
      <br />
      cùng những chương trình khuyến mãi của chúng tôi
    </div>
    <div className="instagram-posts">
      <div style={{
        width: '40%',
      }}
      >
        {post(0)}
      </div>
      <div className="instagram-posts-secondary" style={{ width: '60%' }}>
        <div className="ig-cells" style={{ height: '50%' }}>
          <div className="ig-cell">{post(1)}</div>
          <div className="ig-cell">{post(2)}</div>
          <div className="ig-cell">{post(3)}</div>
        </div>
        <div className="ig-cells" style={{ height: '50%' }}>
          <div className="ig-cell">{post(4)}</div>
          <div className="ig-cell">{post(5)}</div>
          <div className="ig-cell">{post(6)}</div>
        </div>
      </div>
    </div>
  </div>
);
