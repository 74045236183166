/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React from 'react';
import { BOOKING_LINK, SHOP_LINK } from '../../constants/links';
import JnButton from '../JnButton/JnButton';
import './style.css';

export default () => (
  <div
    id="actions-container"
  >
    <div className="action bg-salon">
      <span className="action-text font-light">
        <div>BOOK</div>
        <div>SALON</div>
        <JnButton href={BOOKING_LINK} target="__blank" className="action-button">ĐẶT CHỖ NGAY</JnButton>
      </span>
    </div>
    <div className="action bg-nailbox">
      <span className="action-text font-light">
        <div>SHOP</div>
        <div>NAILBOX</div>
        <JnButton href={SHOP_LINK} target="__blank" className="action-button">MUA SẮM NGAY</JnButton>
      </span>
    </div>
  </div>
);
