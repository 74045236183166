/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.css';
import { BOOKING_LINK } from '../../../constants/links';

const Service = ({
  serviceName,
  logo,
  price,
  className,
}) => (
  <div className={`service ${className}`}>
    <div>
      <a href={BOOKING_LINK} target="__blank">
        <Image src={logo} />
      </a>
    </div>
    <div className="service-description">
      <div className="service-text font-regular">{serviceName.toUpperCase()}</div>
      <div className="service-price font-light">Chỉ từ {price}.000 vnđ</div>
    </div>
  </div>
);

Service.propTypes = {
  serviceName: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  price: PropTypes.number.isRequired,
};

export default Service;
