import React from 'react';
import { Image } from 'react-bootstrap';
import sizing from '../../assets/images/sizing.png';
import TopBar from '../../components/TopBar/TopBar';
import Footer from '../../components/Footer/Footer';

export default () => (
  <>
    <TopBar />
    <div style={{
      margin: '0 auto',
      maxWidth: '80%',
    }}
    >
      <Image src={sizing} />
    </div>
    <Footer />
  </>
);
