import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopBar from '../../components/TopBar/TopBar';
import Banners from '../../components/Banners/Banners';
import Divider from '../../components/Divider/Divider';
import Actions from '../../components/Actions/Actions';
import Services from '../../components/Services/Services';
import NailboxBanner from '../../components/NailboxBanner/NailboxBanner';
import AboutUs from '../../components/AboutUs/AboutUs';
import Commitment from '../../components/Commitment/Commitment';
import Contact from '../../components/Contact/Contact';
import Instagram from '../../components/Instagram/Instagram';
import Footer from '../../components/Footer/Footer';

function Home() {
  return (
    <>
      <TopBar />
      <Banners />
      <Divider />
      <Actions />
      <Services />
      <NailboxBanner />
      <AboutUs />
      <Commitment />
      <Contact />
      <Instagram />
      <Footer />
    </>
  );
}

export default Home;
