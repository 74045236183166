import React from 'react';
import './style.css';

export default () => (
  <div className="about-us">
    <div className="w-50" />
    <div className="about-us-text font-regular">
      <span className="about-us-heading font-bold">VỀ CHÚNG TÔI</span>
      <br />
      <br />
      Tại Just Nails, chúng tôi mong muốn mang đến những dịch vụ chăm sóc móng toàn diện và chuyên nghiệp với chi phí hợp lý nhằm mang đến cho bạn những trải nghiệm làm đẹp chất lượng.
      <br />
      <br />
      Với phương châm an toàn và sạch sẽ, mỗi khách hàng sẽ có một bộ dụng cụ dành riêng và được khử trùng sạch sẽ sau mỗi lần sử dụng. Hơn nữa, đội ngũ nhân viên Just Nails được đào tạo bài bản để làm bạn hài lòng với các dịch vụ của chúng tôi.
    </div>
  </div>
);
