import React from 'react';
import JnButton from '../JnButton/JnButton';
import './style.css';

export default () => (
  <div className="nailbox-banner" id="shopping">
    <div className="nailbox-button">
      <JnButton>
        MUA NGAY
      </JnButton>
      <JnButton href="/sizing" target="__blank">
        HƯỚNG DẪN ĐO MÓNG
      </JnButton>
    </div>
  </div>
);
