import React from 'react';
import { Image } from 'react-bootstrap';
import logoSanity from '../../assets/images/commitment-sanity.png';
import logoFriendly from '../../assets/images/commitment-friendly.png';
import logoSafe from '../../assets/images/commitment-safe.png';
import logoPro from '../../assets/images/commitment-pro.png';
import './style.css';

export default () => (
  <div className="commitment-container">
    <div className="commitment-header font-bold">CHÚNG TÔI CAM KẾT</div>
    <div className="commitments">
      <div className="commitment">
        <Image src={logoSanity} />
        <div>Sạch sẽ</div>
      </div>
      <div className="commitment">
        <Image src={logoFriendly} />
        <div>Thân thiện</div>
      </div>
      <div className="commitment">
        <Image src={logoSafe} />
        <div>An toàn</div>
      </div>
      <div className="commitment">
        <Image src={logoPro} />
        <div>Chuyên nghiệp</div>
      </div>
    </div>
  </div>
);
