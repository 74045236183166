import React from 'react';
import { Image } from 'react-bootstrap';
import Divider from '../Divider/Divider';
import JnButton from '../JnButton/JnButton';
import map from '../../assets/images/map.png';

import './style.css';
import { BOOKING_LINK } from '../../constants/links';

export default () => (
  <div className="contact-container" id="contact">
    <div className="contact-header font-bold">LIÊN HỆ</div>
    <div className="contact-address-map">
      <div className="contact-address">
        <span className="text-header font-semibold">ĐỊA CHỈ</span>
        <span className="text-line font-light">
          2 Lê Văn Miến, Phường Thảo Điền,
          <br />
          Quận 2, Thành phố Thủ Đức
        </span>

        <Divider className="contact-divider" />

        <span className="text-header font-semibold">GIỜ MỞ CỬA</span>
        <span className="text-line font-light">
          Thứ Hai - Chủ Nhật
          <br />
          9:00 - 21:00
        </span>
        <JnButton
          href={BOOKING_LINK}
          target="__blank"
          className="contact-button"
        >
          ĐẶT CHỖ NGAY
        </JnButton>
      </div>
      <div className="contact-map">
        <Image src={map} />
      </div>
    </div>
  </div>
);
