/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Button, Image, Nav, Navbar,
} from 'react-bootstrap';
import './style.css';
import logo from '../../assets/images/logo.png';
import { BOOKING_LINK } from '../../constants/links';

export default () => (
  <Navbar expand="sm" className="top-bar font-semibold">
    <Navbar.Brand href="/">
      <Image src={logo} />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav>
        <Nav.Item className="top-bar-item">
          <Nav.Link href="/">Home</Nav.Link>
        </Nav.Item>
        <Nav.Item className="top-bar-item">
          <Nav.Link href="#services">Trải Nghiệm</Nav.Link>
        </Nav.Item>
        <Nav.Item className="top-bar-item">
          <Nav.Link href="#shopping">Mua Sắm</Nav.Link>
        </Nav.Item>
        <Nav.Item className="top-bar-item">
          <Nav.Link href="#contact">Liên Hệ</Nav.Link>
        </Nav.Item>
        <Nav.Item className="top-bar-item">
          <Button
            href={BOOKING_LINK}
            target="__blank"
            className="booking-btn"
          >
            Đặt Chỗ
          </Button>
          <Nav.Link className="top-bar-item" id="alt-booking-btn" target="__blank" href={BOOKING_LINK}>Đặt Chỗ</Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>

);
