import post1 from '../assets/images/ig-post-1.jpeg';
import post2 from '../assets/images/ig-post-2.jpeg';
import post3 from '../assets/images/ig-post-3.jpeg';
import post4 from '../assets/images/ig-post-4.jpeg';
import post5 from '../assets/images/ig-post-5.jpeg';
import post6 from '../assets/images/ig-post-6.jpeg';
import post7 from '../assets/images/ig-post-7.jpeg';

export const BOOKING_LINK = 'https://backoffice.bepos.io/online-booking?id=U2FsdGVkX19CQ30Is5P28tpp1L2u3S2B2DdSGKAjBEbmEhsOYkngpGAQcULA2sKuih4SoRa7&lang=en&flow=booking';
export const SHOP_LINK = 'https://shopee.vn/justnailsvn';
export const INSTAGRAM = 'https://www.instagram.com/justnailsvn/';
export const IG_POSTS = [
  {
    url: 'https://www.instagram.com/p/CPvI1zptY43/',
    caption: `Màu sắc này có làm bạn nhớ đến mùa hè và những chuyến đi bất tận không?
☀️👙🌊`,
    date: 'June 5, 2021',
    src: post1,
  },
  {
    url: 'https://www.instagram.com/p/CPxttyjs1zf/',
    caption: 'Tông màu nâu cho ai không thích tông màu sáng',
    date: 'June 6, 2021',
    src: post2,
  },
  {
    url: 'https://www.instagram.com/p/CP0T6fds0nK/',
    caption: 'Bây giờ là tông màu sặc sỡ lên kệ đây, comment một "." để thấy điều kỳ diệu',
    src: post3,
    date: 'Jun 6, 2021',
  },
  {
    url: 'https://www.instagram.com/p/CQVuvXGLs0m/',
    caption: 'Bộ nail này được chúng mình chụp vào đợt dịch cho nên lấy hết tất cả mọi thứ ra set up. Mẫu này đang là best-choice của Just Nails đó ;)',
    src: post4,
    date: 'June 20, 2021',
  },
  {
    url: 'https://www.instagram.com/p/CQTKBbdNPX5/',
    caption: 'Có hai con mèo ngồi bên cửa sổ, một con ngồi yên, một con đổi chỗ 🐱🐱',
    src: post5,
    date: 'June 19, 2021',
  },
  {
    url: 'https://www.instagram.com/p/CSQeAzZN2Hl/',
    caption: 'Thỏ chắc là sinh vật đáng iu nhất trái đất rồi 🐰🥕',
    src: post6,
    date: 'Octobor 7, 2021',
  },
  {
    url: 'https://www.instagram.com/p/CQQlJ8jteJh/',
    caption: 'Đôi khi chỉ cần một chút hồng là đủ cho bình mình',
    src: post7,
    date: 'June 18, 2021',
  },
];
