/* eslint-disable react/prop-types */
import React from 'react';
import './style.css';

export default ({
  href,
  src,
  caption, date,
}) => (
  <div
    className="ig-post"
    style={{
      width: '100%',
      height: '100%',
    }}
  >
    <a href={href} target="_blank" rel="noreferrer">
      <div
        className="box"
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
      <div
        className="box ig-text-container"
      >
        <div className="ig-text">
          {caption}
        </div>
        <div className="ig-text">
          {date}
        </div>
      </div>
    </a>
  </div>
);
