import React from 'react';
import Service from './Service/Service';
import serviceBasic from '../../assets/images/service-basic.png';
import serviceNail from '../../assets/images/service-nail.png';
import serviceDecor from '../../assets/images/service-decor.png';
import serviceRelax from '../../assets/images/service-relax.png';

import './style.css';

export default () => {
  const data = [
    {
      serviceName: 'CƠ BẢN',
      logo: serviceBasic,
      price: 50,
    },
    {
      serviceName: 'NỐI MÓNG',
      logo: serviceNail,
      price: 80,
    },
    {
      serviceName: 'TRANG TRÍ',
      logo: serviceDecor,
      price: 20,
    },
    {
      serviceName: 'THƯ GIÃN',
      logo: serviceRelax,
      price: 180,
    },
  ];

  return (
    <div className="services-container" id="services">
      <div className="services-text font-bold">DỊCH VỤ</div>
      <div className="services">
        {
          data.map((item) => <Service className="services-item" serviceName={item.serviceName} logo={item.logo} price={item.price} />)
        }
      </div>
    </div>
  );
};
